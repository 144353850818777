import _ from "lodash";
import { constants as types } from "../reducers/types";

import { SyncDate } from "../../utils/time";

import { evaluateOfStartContent } from "../../utils/synchronize";

import { getRestucturedPlaylistContent } from "../selectors/view";

import { getTimezoneOffset, getOffset, getServerMidnight } from "../selectors/app";

//

export function updateRestructuredPlaylists() {
  return async (dispatch, getState) => {
    const state = getState();

    const playlistContent = getRestucturedPlaylistContent(state);
    dispatch(setRestructuredPlaylistContent(playlistContent));
  };
}

let isSyncContentNoApiCall = false;

export function synchronizeContentNoApiCall(screen) {
  return async (dispatch, getState) => {
    if (isSyncContentNoApiCall) return;
    isSyncContentNoApiCall = true;
    const state = getState();

    console.log("synchronizeContentNoApiCall");

    const { activeScreen: oldActiveScreen, splitIndexFirst, splitIndexSecond } = state.view;
    const { screensList } = state.screens;
    const { simulatedTime } = state.app;

    let playlistContent = state.view.restructuredPlaylistContent;

    if (!playlistContent || !playlistContent[0]) {
      playlistContent = getRestucturedPlaylistContent(state);
      dispatch(setRestructuredPlaylistContent(playlistContent));
    }

    if (!playlistContent || !playlistContent[0]) {
      console.error("No playlist content detected");
      isSyncContentNoApiCall = false;
      return false;
    }

    // find of updated screen in screensList
    const activeScreen = screensList.find((screen) => screen.id === oldActiveScreen.id);
    const timezoneOffset = getTimezoneOffset(state);
    const offset = getOffset(state);
    const serverMidnight = getServerMidnight(state);

    switch (activeScreen.type) {
      case "split": {
        const plFirst = playlistContent[0];
        const shFirst = evaluateOfStartContent(timezoneOffset, offset, plFirst, simulatedTime, serverMidnight);
        const plSecond = playlistContent[1];
        const shSecond = evaluateOfStartContent(timezoneOffset, offset, plSecond, simulatedTime, serverMidnight);

        dispatch(setCurrentIndex(shFirst.countIndex, "splitFirst"));
        dispatch(
          setFirstSplitContent({
            ...shFirst.content,
            remainder: shFirst.remainder,
          })
        );
        dispatch(setCurrentIndex(shSecond.countIndex, "splitSecond"));
        dispatch(
          setSecondSplitContent({
            ...shSecond.content,
            remainder: shSecond.remainder,
          })
        );
        isSyncContentNoApiCall = false;
        return;
      }
      default: {
        const playlist = playlistContent[0];

        const synchronize = evaluateOfStartContent(timezoneOffset, offset, playlist, simulatedTime, serverMidnight);
        //   syncDateNow: new Date(SyncDate.now()),
        //   localTime: new Date(localTime),
        //   syncDateOffset: SyncDate.getOffset(),
        //   stateOffset: offset,
        //   timezoneOffset,
        //   serverMidnight: new Date(serverMidnight),
        //   simulatedTime,
        //   processingTime,
        // });
        const { programmes } = state.programmes;
        const syncedContent = {
          ...synchronize.content,
          remainder: synchronize.remainder,
        };

        const currentPlaylist = activeScreen.playlists.find((p) => p.id === syncedContent.playlistId);

        if (currentPlaylist) {
          const currentTheatre = programmes.find((p) => p.id === currentPlaylist.options?.selectedProgrammeId);
          if (currentTheatre) dispatch(setCurrentTheatre(currentTheatre));
          dispatch(setCurrentPlaylist(currentPlaylist));
        }
        dispatch(setCurrentIndex(synchronize.countIndex, "default"));
        dispatch(setCurrentSyncedContent(syncedContent));

        isSyncContentNoApiCall = false;
      }
    }
  };
}

export function setRestructuredPlaylistContent(playlistContent) {
  return (dispatch) => {
    dispatch({
      type: types.SET_RESTRUCTURED_PLAYLIST_CONTENT,
      payload: { playlistContent },
    });
  };
}

export function setActiveScreen(screen) {
  return (dispatch) => {
    localStorage.setItem("lastActiveScreen", screen.id);
    dispatch({
      type: types.SET_ACTIVE_SCREEN,
      payload: { screen },
    });
  };
}

export function removeActiveScreen() {
  localStorage.removeItem("lastActiveScreen");
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_ACTIVE_SCREEN,
    });
  };
}

export function setLocalTime() {
  return (dispatch) => {
    dispatch({
      type: types.SET_LOCAL_TIME,
    });
  };
}

export function setCurrentIndex(currentIndex, type) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_INDEX,
      payload: { currentIndex, type },
    });
  };
}

export function setCurrentSyncedContent(currentContent) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_CONTENT,
      payload: { currentContent },
    });
  };
}

export function setCurrentViewContent(content) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_VIEW_CONTENT,
      payload: { content },
    });
  };
}

export function setAdditionalCurrentViewContent(content) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ADDITIONAL_CURRENT_VIEW_CONTENT,
      payload: { content },
    });
  };
}

export function setFirstSplitContent(splitSynchContentFirst) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FIRST_SPLIT_CONTENT,
      payload: { splitSynchContentFirst },
    });
  };
}

export function setSecondSplitContent(splitSynchContentSecond) {
  return (dispatch) => {
    dispatch({
      type: types.SET_SECOND_SPLIT_CONTENT,
      payload: { splitSynchContentSecond },
    });
  };
}

function saveViewportTransform(transformProperties) {
  localStorage.setItem("viewportTransform", JSON.stringify(transformProperties));
}

const debounceSaveViewportTransform = _.debounce(saveViewportTransform, 500);

export function setViewportTransform(key, value) {
  return (dispatch, getState) => {
    const state = getState();
    const { viewportTransform } = state.view;

    const newViewportTransform = {
      ...viewportTransform,
      [key]: viewportTransform[key] + value,
    };

    debounceSaveViewportTransform(newViewportTransform);

    dispatch({
      type: types.SET_VIEWPORT_TRANSFORM,
      payload: newViewportTransform,
    });
  };
}

export function viewportResetScale() {
  return (dispatch) => {
    debounceSaveViewportTransform.cancel();
    saveViewportTransform({ x: 0, y: 0, scale: 1 });

    dispatch({ type: types.VIEWPORT_RESET_SCALE });
  };
}

export function setCurrentTheatre(currentTheatre) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_THEATRE,
      payload: { currentTheatre },
    });
  };
}

export function setCurrentPlaylist(currentPlaylist) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_PLAYLIST,
      payload: { currentPlaylist },
    });
  };
}

import React, { Component } from "react";
import SessionList from "../SessionList";
import DefaultScreen from "../DefaultScreen";
import { StyledTomorrowSessions } from "./styles";

const defineProgrammeStyles = ({ isFullScreen }) => {
  const tomorrowHeightPercent = 12;
  const programmeListPercent = 100 - tomorrowHeightPercent;
  if (isFullScreen)
    return {
      gridTemplateRows: `0% 100%`,
    };
  else
    return {
      gridTemplateRows: `${tomorrowHeightPercent}% ${programmeListPercent}%`,
    };
};

export default class TomorrowSession extends Component {
  constructor(props) {
    super(props);
    this.tomorrowSessionRef = React.createRef();
  }

  state = {
    pointPosition: 0,
  };

  componentDidMount() {
    const pointRect = this.tomorrowSessionRef.current.getBoundingClientRect().bottom;
    this.setState({
      pointPosition: pointRect,
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const pointRect = this.tomorrowSessionRef.current.getBoundingClientRect().bottom;
    if (prevState.pointPosition !== pointRect) {
      return pointRect;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      this.setState({
        pointPosition: snapshot,
      });
    }
  }

  render() {
    const {
      options,
      localTime,
      programmes,
      restToDayList,
      currentViewContent: { type: currentViewContentType },
      tomorrowDayList,
      tomorrowDayList: { nextDayOfSessionsName },
      rootFontSize,
      isFullScreen,
    } = this.props;

    const { pointPosition } = this.state;

    const areSessionsLeft = tomorrowDayList.sessions.length;
    const {
      themeColor: { hex },
      fontScale,
      descriptionLineSpacing,
      titleLineSpacing,
      speakersLineSpacing,
      elementsSpacing,
    } = options;
    const fontScalePercentage = Number(fontScale) || 100;
    const fontFamily = options.fontFamily || "Open Sans";

    const isProgrammeTheCurrentContent = currentViewContentType === "programme";

    return (
      <StyledTomorrowSessions fontFamily={fontFamily} fontScalePercentage={fontScalePercentage} titleLineSpacing={titleLineSpacing} descriptionLineSpacing={descriptionLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} hex={hex} ref={this.tomorrowSessionRef} className="container" style={defineProgrammeStyles({ isFullScreen })}>
        <div className="infoText">{`${nextDayOfSessionsName}'s Programme`}</div>
        {isProgrammeTheCurrentContent && <SessionList rootFontSize={rootFontSize} fontScalePercentage={fontScalePercentage} titleLineSpacing={titleLineSpacing} descriptionLineSpacing={descriptionLineSpacing} speakersLineSpacing={speakersLineSpacing} elementsSpacing={elementsSpacing} hex={hex} fontFamily={fontFamily} sessionList={tomorrowDayList.sessions} pointPosition={pointPosition} areSessionsLeft={areSessionsLeft} programmes={programmes} />}

        <div className={!isProgrammeTheCurrentContent ? "show" : "hide"}>
          <div className={!isProgrammeTheCurrentContent ? "openTop" : null} />
          <div className={!isProgrammeTheCurrentContent ? "openBottom" : null} />

          <DefaultScreen {...this.props} />
        </div>
      </StyledTomorrowSessions>
    );
  }
}

import { createSelector } from "reselect";
import _ from "lodash";
import getFlatPlaylists from "../../utils/get-flat-playlist";
import arrayIdInjector from "../../utils/array-id-injector";

const isProgramme = ({ entityType }) => entityType === "programme";

export const selectorActiveScreen = (state) => state.view.activeScreen;

export const getRestucturedPlaylistContent = (state) => {
  const activeScreen = state.view.activeScreen;
  const contentGroup = state.screens.contentGroup;
  const programmes = state.programmes.programmes;
  const timezoneOffset = state.app.timezoneOffset;
  const offset = state.app.offset;
  const simulatedTime = state.app.simulatedTime;
  const serverMidnight = state.app.serverMidnight;

  if (!activeScreen) return null;

  const { nestedContentMeta = [], playlists = [] } = activeScreen;

  // ADDED BY DINO AS A HOTFIX FOR INCOS 2025 FORMULATION LABS SCREEN ISSUE
  if (activeScreen.id === 1919 && activeScreen.name === "Formulation Labs 1 & 2" && playlists.length === 3) {
    playlists.shift();
    nestedContentMeta.shift();
  }

  function addContentUrlToNestedPlaylistContent(cmArr, contents = []) {
    return cmArr
      .map((cm) => {
        if (Array.isArray(cm)) {
          return arrayIdInjector(addContentUrlToNestedPlaylistContent(cm, contents), cm.id);
        }

        const contentObj = contents.find((c) => c.contentGroupId === cm.entityId);

        return {
          ...cm,
          content: cm.entityType === "programme" ? "programme" : contentObj.content,
          type: cm.entityType === "programme" ? "programme" : contentObj.type,
          fileName: cm.entityType === "programme" ? "programme" : contentObj.fileName,
        };
      })
      .filter((content) => (activeScreen.type === "theatre" ? true : !isProgramme(content)));
  }
  const nestedContentMetaWithContentLink = nestedContentMeta.map((cmArr, idx) => addContentUrlToNestedPlaylistContent(cmArr, playlists[idx].contents));

  const flattenContentMeta = activeScreen.type === "theatre" ? [nestedContentMetaWithContentLink.flat(1)] : nestedContentMetaWithContentLink;
  const flatPlaylists = getFlatPlaylists(flattenContentMeta, contentGroup, programmes, timezoneOffset, offset, activeScreen.type, simulatedTime, serverMidnight);

  return flatPlaylists;
};

// const getProgress = (state) => state.screens.progress;
// const getActiveScreenId = (state) => (state.view.activeScreen || {}).id || {};

// export const getActiveScreenProgress = createSelector(
//   getProgress,
//   getActiveScreenId,
//   (progressObj, activeScreenId) =>
//     progressObj && activeScreenId && progressObj[activeScreenId]
// );

export const getNestedContentMeta = createSelector([selectorActiveScreen], (screen) => {
  // function getPlaylistIds(playlist) {
  //   if (playlist.type === 'theatre') return [playlist.options.selectedPlaylistId];
  //   if (playlist.type === 'split') return [playlist.options.firstPlaylistId, playlist.options.secondPlaylistId];
  //   return [playlist.id];
  // }

  function mapContent(arr) {
    const sortedArr = arr.sort((a, b) => a.order - b.order);
    return sortedArr.map((content) => {
      if (content.entityType === "playlist") {
        // const nestedPlaylist = playlistContents.find(p => p.id === content.entityId);
        return mapContent(content.contentMeta);
      }
      return content;
    });
  }

  // const playlistIds = getPlaylistIds(screen.playlists[0]);
  // const playlistsWithMeta = playlistIds.map(id => playlistContents.find(p => p.id === id));
  // return playlistsWithMeta.map(plMeta => mapContent(plMeta.contentMeta));
  return screen.playlists.map((playlist) => mapContent(playlist.contentMeta));
});

const selectorSplitScreenData = (state, ownProps) => ({
  currentIndexFirst: state.view.splitIndexFirst,
  currentIndexSecond: state.view.splitIndexSecond,
  playlistContentSplitScreenFirst: ownProps.playlistContentSplitScreenFirst,
  playlistContentSplitScreenSecond: ownProps.playlistContentSplitScreenSecond,
});

const selectorOptions = (state) => state.view.activeScreen.options;

export const defineSplitScreenStyles = createSelector([selectorSplitScreenData, selectorOptions], (splitScreenData, options) => {
  const { currentIndexFirst, currentIndexSecond, playlistContentSplitScreenFirst, playlistContentSplitScreenSecond } = splitScreenData;
  const {
    sizes: { firstPlaylist, secondPlaylist, bottomBar },
  } = options;

  // TODO: if either is empty, this causes a crash, this is a temp fix
  if (!playlistContentSplitScreenFirst || !playlistContentSplitScreenSecond) {
    return "100% 0 0";
  }

  const currentFirst = playlistContentSplitScreenFirst[currentIndexFirst] || playlistContentSplitScreenFirst[0];
  const currentSecond = playlistContentSplitScreenSecond[currentIndexSecond] || playlistContentSplitScreenSecond[0];

  const { isFullScreen: firstIsFull } = currentFirst || {};
  const { isFullScreen: secondIsFull } = currentSecond || {};

  if ((firstIsFull && secondIsFull) || firstIsFull) {
    return "100% 0 0";
  }

  if (secondIsFull) {
    return "0 100% 0";
  }

  return `${firstPlaylist}% ${secondPlaylist}% ${bottomBar}%`;
});
